import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header({ onMenuClick }) {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const handleMenuClick = e => {
    toggleExpansion(false);
    if (onMenuClick) {
      onMenuClick(e);
    }
  };

  return (
    <header className="absolute w-full py-4 px-6 bg-white md:bg-transparent z-50">
      <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto my-4">
        <Link className="flex items-center no-underline text-white" to="/">
          <img src="/logo.png" className="w-29 lg:w-32" />
        </Link>

        <button
          className="block md:hidden border border-white flex items-center rounded"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-6 h-6"
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path
              d="M1.5 1.5h21M1.5 17.5h21M1.5 9.5h21"
              stroke="currentColor"
              strokeWidth="3"
              strokeLinecap="square"
            />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded
              ? `flex flex-col pb-5 border-t border-gray-40 mt-4 md:border-0 z-50`
              : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto md:space-x-8`}
        >
          {[
            {
              route: `/#how-to-accept-payment`,
              title: "How to accept Payment"
            },
            {
              route: `/#benefits`,
              title: "Benefits"
            }
          ].map(link => (
            <Link
              className="block md:inline-block no-underlin text-black py-5 md:py-2 font-semibold border-b border-gray-40 md:border-0 hover:text-pink-50 transition duration-300"
              key={link.title}
              onClick={handleMenuClick}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          <Link
            to="/#contact"
            className="border bg-white text-pink-50 px-5 py-2 rounded-sm font-semibold uppercase hover:bg-pink-40 hover:text-white transition duration-300 text-center my-5 md:my-0"
            onClick={handleMenuClick}
          >
            Contact
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
