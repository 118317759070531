import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import Header from "components/Header";
import MarkerIcon from "assets/marker.local.svg";
import Dot from "assets/dot.local.svg";
import MailIcon from "assets/mail.local.svg";
import Phone from "assets/phone.local.svg";

function Layout({ onMenuClick, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            phone
          }
        }
      }
    `
  );
  const { siteMetadata } = site;
  const refs = {
    "#contact": useRef(null)
  };
  const handleMenuClick = e => {
    const { hash } = new URL(e.target.href);
    const el = refs[hash];

    if (el && el.current) {
      el.current.scrollIntoView({ behavior: "smooth" });
    }

    if (onMenuClick) {
      return onMenuClick(e);
    }
  };

  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900 bg-white">
      <Header onMenuClick={handleMenuClick} />

      <main className="flex flex-col flex-1 md:justify-center w-full">
        {children}
      </main>

      <footer
        className="bg-pink-90 relative overflow-hidden"
        id="contact"
        ref={refs["#contact"]}
      >
        <div className="bg-pink-100 text-center px-2 py-6 text-sm text-white flex items-center justify-center space-y-2 lg:space-y-0 lg:space-x-16 flex-col lg:flex-row">
          <strong className="text-lg lg:text:2xl">Get in Touch:</strong>
          <div className="flex space-x-4 lg:space-x-16">
            <a
              className="flex items-center lg:text-xl lg:space-x-2"
              href={`mailto:${siteMetadata.email}`}
            >
              <span className="flex items-center justify-center rounded-full p-2 bg-gray-90 w-8 h-8 lg:w-12 lg:h-12">
                <MailIcon />
              </span>{" "}
              <span>{siteMetadata.email}</span>
            </a>
            <a
              className="flex items-center lg:text-xl space-x-2"
              href={`tel:${siteMetadata.phone}`}
            >
              <span className="flex items-center justify-center rounded-full p-2 bg-gray-90 w-8 h-8 lg:w-12 lg:h-12">
                <Phone />
              </span>
              <span>{siteMetadata.phone}</span>
            </a>
          </div>
        </div>
        <div className="px-6 py-12 max-w-6xl mx-auto relative overflow-hidden">
          <Dot className="absolute right-0 lg:block mt-32 -mr-24 lg:mr-0 transform rotate-45 lg:bottom-0 lg:right-0 lg:transform lg:mt-64 opacity-25" />
          <div className="grid grid-cols-1 lg:grid-cols-4 relative">
            <div className="lg:pt-16">
              <img src="/logo.png" className="rounded-lg w-40 z-10" />
              <Dot className="absolute hidden lg:top-0 lg:block lg:ml-16 opacity-25" />
            </div>

            <div className="pt-10 lg:pt-4 relative overflow-hidden">
              <span className="uppercase text-semi-transparent font-bold">
                Quick Links
              </span>
              <ul className="mt-3 grid grid-cols-2 lg:grid-cols-1">
                <li className="text-white py-2">
                  <Link to="/">Home</Link>
                </li>
                <li className="text-white py-2">
                  <Link to="/#how-to-accept-payment" onClick={handleMenuClick}>
                    How to accept payment
                  </Link>
                </li>
                <li className="text-white py-2">
                  <Link to="/#benefits" onClick={handleMenuClick}>
                    Benefits
                  </Link>
                </li>
              </ul>
            </div>

            <div className="pt-10 lg:pt-4">
              <span className="uppercase text-semi-transparent font-bold">
                Others
              </span>
              <ul className="mt-3 grid grid-cols-2 lg:grid-cols-1">
                <li className="text-white py-2">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="text-white py-2">
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>

            <div className="">
              <div className="pt-10 lg:pt-4">
                <span className="uppercase text-semi-transparent font-bold">
                  Registered Office
                </span>
                <div className="flex mt-5">
                  <MarkerIcon className="mt-2" />
                  <div className="text-white ml-4 text-sm leading-loose">
                    <p>#677, 1st Floor,</p>
                    <p> 13th Cross Road, 27th Main Rd,</p>
                    <p>1st Sector, HSR Layout</p>
                    <p>Bengaluru, Karnataka 560102</p>
                  </div>
                </div>
              </div>

              <div className="pt-10 lg:pt-4">
                <span className="uppercase text-semi-transparent font-bold">
                  Development centre
                </span>
                <div className="flex mt-5">
                  <MarkerIcon className="mt-2" />
                  <div className="text-white ml-4 text-sm leading-loose">
                    <p>1157, 1st Floor, 20th Cross Rd,</p>
                    <p>Sector 5, Sector 7, HSR Layout,</p>
                    <p>Bengaluru, Karnataka 560102</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-pink-100 text-center px-2 py-4 text-sm text-white">
          ©2020 Uvik Technologies PVT LTD. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
